import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';

// Contexts
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

// Utils
import { richText } from '../../util/richText';
import { types as sdkTypes } from '../../util/sdkLoader';
import { convertMoneyToNumber } from '../../util/currency';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import {
  createSlug,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';

import {
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

// Global ducks (for Redux actions and thunks)
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import {
  sendReply,
  sendReview,
  sendInquiry,
  fetchTimeSlots,
  setInitialValues,
  fetchTransactionLineItems,
} from './ListingPage.duck';

// Shared components
import {
  H3,
  H4,
  Page,
  NamedLink,
  BackButton,
  OrderPanel,
  IconSpinner,
  NamedRedirect,
  ListingCardNew,
  SecondaryButton,
  LayoutSingleColumn,
} from '../../components';

// Related components and modules
import css from './ListingPage.module.css';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import FooterContainer from '../FooterContainer/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionGallery from './SectionGallery';
import SectionReviews from './SectionReviews';
import SectionMapMaybe from './SectionMapMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import CustomListingFields from './CustomListingFields';

const { UUID } = sdkTypes;
const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const matchedServices = (itemServices, currentListingServices) => {
  return itemServices.some(itemService =>
    currentListingServices.some(currentListingService => {
      return currentListingService?.value === itemService?.value;
    })
  );
};

const getSimilarListings = (listings, currentListingData) => {
  const matchedListingsByServices = listings?.filter(listing =>
    Object.keys(currentListingData).some(key => {
      if (
        key === 'servicesType' &&
        Array.isArray(listing[key]) &&
        Array.isArray(currentListingData[key])
      ) {
        return matchedServices(listing[key], currentListingData[key]);
      }
    })
  );

  const matchedListingsByCategories = matchedListingsByServices?.filter(listing => {
    const { category, subCategory } = listing;
    const isCategoryMatched = currentListingData?.category === category;
    const isSubCategoryMatched = currentListingData?.subCategory === subCategory;
    return isCategoryMatched && isSubCategoryMatched;
  });

  return matchedListingsByCategories;
};

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );
  const {
    intl,
    listings,
    searchInProgress,
    searchListingsError,
    currentUser,
    isAuthenticated,
    getListing,
    getOwnListing,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviewsData,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    sendReviewInProgress,
    sendReviewSuccess,
    sendReviewError,
    onSendReview,
    onSendReply,
    sendReplyInProgress,
    sendReplySuccess,
    sendReplyError,
  } = props;
  const listingConfig = config.listing;
  const listingId = new UUID(rawParams.id);
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const { category, subCategory, subSubCategory, servicesType, locationFromUser } =
    currentListing?.attributes?.publicData || {};

  const currentListingData = {
    category,
    subCategory,
    subSubCategory,
    servicesType,
    locationFromUser,
    key: currentListing?.id?.uuid,
  };

  const flatListings = listings?.map(listing => {
    const {
      publicData,
      category = publicData?.category,
      subCategory = publicData?.subCategory,
      subSubCategory = publicData?.subSubCategory,
      servicesType = publicData?.servicesType,
      locationFromUser = publicData?.locationFromUser,
    } = listing?.attributes || {};

    return {
      category,
      subCategory,
      subSubCategory,
      servicesType,
      locationFromUser,
      key: listing?.id?.uuid,
    };
  });

  const flatSimilarListings = getSimilarListings(flatListings, currentListingData);

  const similarListings = listings.filter(listing => {
    if (listing?.id?.uuid === currentListing?.id?.uuid) {
      return false;
    } else {
      return flatSimilarListings.some(
        flatSimilarListing => flatSimilarListing.key === listing.id.uuid
      );
    }
  });

  const firstFourSimilarListings = similarListings?.slice(0, 4);
  const isCurrentListingRfq = currentListing?.attributes?.publicData?.isRfq;
  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage staticContext={props.staticContext} />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;
  const currentListingLocation = publicData?.locationFromUser?.search;
  const richTitle = (
    <React.Fragment>
      {richText(title, {
        longWordClass: css.longWord,
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
      })}
    </React.Fragment>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const { listingType, transactionProcessAlias, unitType } = publicData;
  if (!(listingType && transactionProcessAlias && unitType)) {
    // Listing should always contain listingType, transactionProcessAlias and unitType)
    return (
      <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} invalidListing />
    );
  }
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const isBooking = isBookingProcess(processName);
  const isPurchase = isPurchaseProcess(processName);
  const processType = isBooking ? ('booking' ? isPurchase : 'purchase') : 'inquiry';

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const noPayoutDetailsSetWithOwnListing =
    isOwnListing && processType !== 'inquiry' && !currentUser?.attributes?.stripeConnected;

  const payoutDetailsWarning = noPayoutDetailsSetWithOwnListing ? (
    <span className={css.payoutDetailsWarning}>
      <FormattedMessage id="ListingPage.payoutDetailsWarning" values={{ processType }} />
      <NamedLink name="StripePayoutPage">
        <FormattedMessage id="ListingPage.payoutDetailsWarningLink" />
      </NamedLink>
    </span>
  ) : null;

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');
  const { formattedPrice } = priceData(price, config.currency, intl);
  const commonParams = { params, history, routes: routeConfiguration };

  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });

  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });

  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const twitterImages = listingImages(currentListing, 'twitter');
  const facebookImages = listingImages(currentListing, 'facebook');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);

  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );

  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
        price: intl.formatNumber(convertMoneyToNumber(price), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        priceCurrency: price.currency,
      }
    : {};

  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  return (
    <Page
      title={schemaTitle}
      description={description}
      author={authorDisplayName}
      twitterImages={twitterImages}
      facebookImages={facebookImages}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        <div className={css.responsiveContainer}>
          <div className={css.backAndEditButton}>
            <BackButton className={css.backButtonCustom} />

            {currentListing.id ? (
              <ActionBarMaybe
                listing={currentListing}
                isOwnListing={isOwnListing}
                isCurrentListingRfq={isCurrentListingRfq}
                editParams={{
                  id: listingId.uuid,
                  tab: listingTab,
                  slug: listingSlug,
                  type: listingPathParamType,
                }}
              />
            ) : null}
          </div>

          <section className={css.mainContent}>
            <div className={css.mainColumnForProductLayout}>
              {currentListing.id && noPayoutDetailsSetWithOwnListing ? (
                <ActionBarMaybe
                  className={css.actionBarForProductLayout}
                  isOwnListing={isOwnListing}
                  listing={currentListing}
                  showNoPayoutDetailsSet={noPayoutDetailsSetWithOwnListing}
                />
              ) : null}

              <div>
                <SectionGallery
                  listing={currentListing}
                  variantPrefix={config.layout.listingImage.variantPrefix}
                />
              </div>

              <div className={css.mobileHeading}>
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              </div>

              {/* <SectionTextMaybe text={description} showAsIngress /> */}

              <CustomListingFields
                publicData={publicData}
                metadata={metadata}
                listingFieldConfigs={listingConfig.listingFields}
                categoryConfiguration={config.categoryConfiguration}
                intl={intl}
              />

              <div className={css.mapContainer}>
                <SectionMapMaybe
                  isLocationTitle={true}
                  publicData={publicData}
                  mapsConfig={config.maps}
                  geolocation={geolocation}
                  listingId={currentListing.id}
                  customLocationHeader={currentListingLocation}
                />
              </div>
            </div>

            <div className={css.orderColumnForProductLayout}>
              <OrderPanel
                className={css.productOrderPanel}
                listing={currentListing}
                reviewsData={reviewsData}
                isOwnListing={isOwnListing}
                onSubmit={handleOrderSubmit}
                isCurrentListingRfq={isCurrentListingRfq}
                authorLink={
                  <NamedLink
                    className={css.authorNameLink}
                    name="ListingPage"
                    params={params}
                    to={{ hash: '#author' }}
                  >
                    {authorDisplayName}
                  </NamedLink>
                }
                title={
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                }
                titleDesktop={
                  <H4 as="h1" className={css.orderPanelTitle}>
                    <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                  </H4>
                }
                author={ensuredAuthor}
                onContactUser={onContactUser}
                payoutDetailsWarning={payoutDetailsWarning}
                onManageDisableScrolling={onManageDisableScrolling}
                monthlyTimeSlots={monthlyTimeSlots}
                onFetchTimeSlots={onFetchTimeSlots}
                onFetchTransactionLineItems={onFetchTransactionLineItems}
                lineItems={lineItems}
                fetchLineItemsInProgress={fetchLineItemsInProgress}
                fetchLineItemsError={fetchLineItemsError}
                validListingTypes={config.listing.listingTypes}
                marketplaceCurrency={config.currency}
                dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                marketplaceName={config.marketplaceName}
              />
            </div>
          </section>

          {!isCurrentListingRfq && (
            <section id="Similar-Listings-Section" className={css.similarListingPanel}>
              {searchInProgress ? (
                <H3 className={css.header}>
                  <FormattedMessage id="ListingPage.loadingSimilarListingTitle" />
                </H3>
              ) : firstFourSimilarListings?.length > 0 ? (
                <H3 className={css.header}>
                  {richText(
                    intl.formatMessage(
                      { id: 'ListingPage.LoadedSimilarListingsTitle' },
                      {
                        count: firstFourSimilarListings.length,
                        title: currentListing?.attributes?.title,
                      }
                    ),
                    {
                      longWordClass: css.longWord,
                      longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
                    }
                  )}
                </H3>
              ) : (
                <H3 className={css.header}>
                  <FormattedMessage id="ListingPage.noSimilarListingsTitle" />
                </H3>
              )}

              {searchInProgress ? (
                <IconSpinner />
              ) : firstFourSimilarListings?.length > 0 ? (
                <div className={css.similarListingCards}>
                  {firstFourSimilarListings.map(l => (
                    <ListingCardNew
                      listing={l}
                      key={l.id.uuid}
                      author={l.author}
                      currentUser={currentUser}
                    />
                  ))}
                </div>
              ) : (
                <H4 className={css.noSimilarListing}>
                  {richText(
                    intl.formatMessage(
                      { id: 'ListingPage.noSimilarListingsDescription' },
                      {
                        title: currentListing?.attributes?.title,
                      }
                    ),
                    {
                      longWordClass: css.longWord,
                      longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
                    }
                  )}
                </H4>
              )}

              <div className={css.buttonsWrapper}>
                <SecondaryButton className={css.viewMore}>
                  <NamedLink name="SearchPage">
                    <FormattedMessage id={'ListingPage.viewMore'} />
                  </NamedLink>
                </SecondaryButton>
              </div>
            </section>
          )}

          {!isCurrentListingRfq && (
            <section id="Review-Section" name="Review-Section">
              <SectionReviews
                reviewsData={reviewsData}
                fetchReviewsError={fetchReviewsError}
                onManageDisableScrolling={onManageDisableScrolling}
                title={title}
                sendReviewInProgress={sendReviewInProgress}
                sendReviewSuccess={sendReviewSuccess}
                sendReviewError={sendReviewError}
                onSendReview={onSendReview}
                listingId={listingId.uuid}
                author={ensuredAuthor}
                onSendReply={onSendReply}
                sendReplyInProgress={sendReplyInProgress}
                sendReplySuccess={sendReplySuccess}
                sendReplyError={sendReplyError}
                authorDisplayName={authorDisplayName}
                isAuthenticated={isAuthenticated}
              />
            </section>
          )}

          {/* <section id="Author-Section" name="Author-Section">
            <SectionAuthorMaybe
              title={title}
              listing={currentListing}
              authorDisplayName={authorDisplayName}
              onContactUser={onContactUser}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
            />
          </section> */}
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviewsData: {},
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,

  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviewsData: object,
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviewsData,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    sendReviewInProgress,
    sendReviewSuccess,
    sendReviewError,
    sendReplyInProgress,
    sendReplySuccess,
    sendReplyError,

    /* required to fetch all listings */
    searchInProgress,
    searchListingsError,
    currentPageResultIds,
    /* required to fetch all listings */
  } = state.ListingPage;

  const listings = getListingsById(state, currentPageResultIds);
  // const listingsExceptRfqs = listings?.filter(listing => !listing?.attributes?.publicData?.isRfq);

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    getListing,
    getOwnListing,
    currentUser,
    isAuthenticated,
    listings,
    searchInProgress,
    searchListingsError,
    showListingError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    sendReviewInProgress,
    sendReviewSuccess,
    sendReviewError,
    sendReplyInProgress,
    sendReplySuccess,
    sendReplyError,
    fetchReviewsError,
    scrollingDisabled: isScrollingDisabled(state),
    reviewsData: typeof reviewsData !== 'object' ? {} : reviewsData,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onSendReview: (listingId, data) => dispatch(sendReview(listingId, data)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onSendReply: (listingId, reviewId, reply) => dispatch(sendReply(listingId, reviewId, reply)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(connect(mapStateToProps, mapDispatchToProps))(EnhancedListingPage);

export default ListingPage;
